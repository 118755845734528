<script setup>
import Toggle from '@forms/Toggle.vue'
import { ref, watch } from 'vue'
import { http } from '@modules/useHttp'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  status: {
    type: Number,
    required: true,
  },
})

const isOnline = ref(props.status === 1)

watch(() => isOnline.value, (active) => {
  http().patch(`/slw/${props.id}/status`, { active })
})
</script>

<template>
  <div class="tw-flex tw-items-center tw-gap-x-2 tw-text-xs tw-font-medium">
    <Toggle v-model="isOnline" size="sm" disabled-background="tw-bg-red-400" disabled-border="tw-border-red-500" />

    <div v-if="isOnline" class="tw-text-accent-100">
      Website Online
    </div>
    <div v-else class="tw-text-red-500">
      Website Offline
    </div>
  </div>
</template>
