<script setup>
import { computed } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import Icon from '@shared/icons/Icon.vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  items: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: 'tw-w-full',
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})
</script>

<template>
  <Listbox v-model="model" as="div">
    <ListboxLabel v-if="label" class="tw-block tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-900" v-text="label" />
    <div :class="width" class="tw-relative">
      <ListboxButton
        class="tw-relative tw-w-full tw-cursor-pointer tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-left tw-text-sm tw-text-primary-100 sm:tw-leading-6"
      >
        <span class="tw-block tw-truncate" v-text="model.name" />
        <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
          <Icon icon="ChevronDownIcon" class="tw-h-5 tw-w-5 tw-text-gray-500" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="tw-transition tw-ease-in tw-duration-100" leave-from-class="tw-opacity-100" leave-to-class="tw-opacity-0">
        <ListboxOptions class="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-text-sm tw-leading-[17px] tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
          <ListboxOption v-for="(item, index) in items" :key="index" v-slot="{ active, selected }" as="template" :value="item">
            <li class="tw-relative tw-m-2 tw-cursor-pointer tw-select-none tw-rounded tw-text-left tw-text-primary-100" :class="[active || selected ? 'tw-bg-temp-gray-100' : '']">
              <span class="tw-block tw-truncate tw-p-2 focus-visible:tw-outline-none" v-text="item.name" />
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
