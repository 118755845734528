<script setup>
import { computed } from 'vue'
import { formatField } from '@modules/useForm'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  store: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const field = formatField(props.label)

if (props.store) {
  localStorage.setItem(field.value, model.value.toString())
}
</script>

<template>
  <div class="tw-flex tw-items-center">
    <input :id="field" v-model="model" :name="field" type="checkbox" class="tw-pointer-events-auto tw-relative tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-secondary-100 tw-opacity-100 checked:tw-bg-secondary-100 focus:tw-outline-none focus:tw-ring-0">
    <label :for="field" class="tw-ml-2 tw-block tw-text-sm tw-text-gray-900" v-text="label" />
  </div>
</template>
