<script setup>
import { computed } from 'vue'
import { useDomainStore } from '@stores/domain'
import Icon from '@shared/icons/Icon.vue'

const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
})

const onLastStep = computed(() => props.steps[props.steps.length - 1].status === 'current')
</script>

<template>
  <div class="tw-flex tw-items-center">
    <template v-for="({ tab, status, icon }, index) in steps" :key="tab">
      <template v-if="status === 'complete'">
        <div v-if="onLastStep" class="tw-relative tw-flex tw-items-center tw-text-accent-100">
          <span class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-accent-100 tw-py-3">
            <Icon :icon="icon" class="tw-h-6 tw-w-6" />
          </span>
          <span class="tw-absolute tw-top-0 -tw-ml-10 tw-mt-16 tw-w-32 tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-accent-100" v-text="tab" />
        </div>
        <button v-else type="button" class="tw-relative tw-flex tw-items-center tw-text-accent-100 tw-transition tw-duration-500 tw-ease-in-out hover:tw-text-temp-teal-700 focus:tw-bg-transparent" @click="useDomainStore().setActiveStep(tab)">
          <span class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-accent-100 tw-py-3 hover:tw-border-temp-teal-700">
            <Icon :icon="icon" class="tw-h-6 tw-w-6" />
          </span>
          <span class="tw-absolute tw-top-0 -tw-ml-10 tw-mt-16 tw-w-32 tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-accent-100 hover:tw-text-temp-teal-700" v-text="tab" />
        </button>

        <div class="tw-flex-auto tw-border-t-2 tw-border-accent-100 tw-transition tw-duration-500 tw-ease-in-out" />
      </template>

      <template v-else-if="status === 'current'">
        <div class="tw-relative tw-flex tw-items-center tw-text-white">
          <span class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-accent-100 tw-bg-accent-100 tw-py-3 tw-transition tw-duration-500 tw-ease-in-out">
            <Icon :icon="icon" class="tw-h-6 tw-w-6" />
          </span>
          <span class="tw-absolute tw-top-0 -tw-ml-10 tw-mt-16 tw-w-32 tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-accent-100" v-text="tab" />
        </div>
        <div v-if="index < steps.length - 1" class="tw-flex-auto tw-border-t-2 tw-border-gray-300 tw-transition tw-duration-500 tw-ease-in-out" />
      </template>

      <template v-else-if="status === 'upcoming'">
        <div class="tw-relative tw-flex tw-items-center tw-text-gray-500">
          <span class="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-gray-300 tw-py-3 tw-transition tw-duration-500 tw-ease-in-out">
            <Icon :icon="icon" class="tw-h-6 tw-w-6" />
          </span>
          <span class="tw-absolute tw-top-0 -tw-ml-10 tw-mt-16 tw-w-32 tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-gray-500" v-text="tab" />
        </div>
        <div v-if="index < steps.length - 1" class="tw-flex-auto tw-border-t-2 tw-border-gray-300 tw-transition tw-duration-500 tw-ease-in-out" />
      </template>
    </template>
  </div>
</template>
