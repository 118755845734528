<script setup>
import { computed } from 'vue'
import Icon from '@shared/icons/Icon.vue'
import LoadingDots from '@shared/loading/LoadingDots.vue'
import { buttonIconSizes, buttonSizes, circleButtonIconSizes, circleButtonSizes } from '@/config'

const props = defineProps({
  as: {
    type: String,
    default: 'button',
  },
  type: {
    type: String,
    default: 'default', // options: default, circle
  },
  size: {
    type: String,
    default: 'md',
  },
  icon: {
    type: String,
    default: '',
  },
  iconClass: {
    type: String,
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  submittingText: {
    type: String,
    default: 'Submitting',
  },
})

const sizeClasses = computed(() => (props.type === 'circle') ? circleButtonSizes[props.size] : buttonSizes[props.size])
const iconSizeClasses = computed(() => (props.type === 'circle') ? circleButtonIconSizes[props.size] : buttonIconSizes[props.size])
</script>

<template>
  <button
    :type="as"
    :class="[sizeClasses, isSubmitting ? 'tw-pointer-events-none' : '']"
    :disabled="isDisabled"
    class="tw-button-secondary"
  >
    <template v-if="icon">
      <Icon :icon="icon" :class="[iconSizeClasses, iconClass]" />
    </template>

    <template v-if="isSubmitting">
      <span v-text="submittingText" />
      <LoadingDots color="tw-bg-primary-200" size="sm" />
    </template>
    <template v-else>
      <slot />
    </template>
  </button>
</template>
