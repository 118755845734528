<script setup>
import { computed } from 'vue'
import { useDomainStore } from '@stores/domain'
import { PrimaryButton } from '@forms/index'
import WarningAlert from '@shared/alerts/WarningAlert.vue'
import { formatUsdYearly } from '@modules/useCurrency'

const props = defineProps({
  searchedDomain: {
    type: String,
    required: true,
  },
  domains: {
    type: Array,
    required: true,
  },
})

const domainStore = useDomainStore()
const unavailableDomainTitle = computed(() => `${props.searchedDomain} is not available.`)

const reserveDomainHandler = (domain) => {
  domainStore.setAvailableDomain(domain)
  domainStore.setActiveStep('Verify')
  domainStore.prepareDomain()
}
</script>

<template>
  <WarningAlert class="tw-mt-8">
    <div v-text="unavailableDomainTitle" />
  </WarningAlert>

  <template v-if="domains.length === 0">
    <div class="tw-mt-4 tw-font-medium">
      Sorry, no auto-suggested domains were found.
    </div>
  </template>

  <template v-else>
    <div class="tw-mt-4 tw-font-medium">
      Here are some alternatives:
    </div>

    <ul role="list" class="tw--mr-2.5 tw-h-full tw-divide-y tw-divide-gray-100 tw-overflow-y-auto">
      <li v-for="domain in domains" :key="domain.domain" class="tw-flex tw-items-center tw-gap-x-6 tw-py-5 tw-pr-2.5">
        <div class="tw-flex tw-flex-1 tw-flex-col tw-items-start sm:tw-flex-row sm:tw-items-center">
          <div class="tw-flex-1 tw-leading-6 tw-text-gray-900" v-text="domain.domain" />
          <div class="tw-text-right tw-text-sm tw-leading-6 tw-text-gray-900" v-text="formatUsdYearly(domain.price)" />
        </div>

        <div>
          <PrimaryButton size="sm" @click="reserveDomainHandler(domain)">
            Get it
          </PrimaryButton>
        </div>
      </li>
    </ul>
  </template>
</template>
