<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@stores/auth'
import { forgotPassword } from '@modules/useAuth'
import { FormInput, LinkButton, PrimaryButton } from '@forms/index'

const authStore = useAuthStore()

const form = ref({
  email: '',
})
const sentResetLink = ref(false)
const resetLinkConfirmationMessage = ref('')

const handlePasswordReset = async () => {
  const response = await forgotPassword(form.value)

  if (response) {
    authStore.setCurrentPageTitle('Check Your Email')
    resetLinkConfirmationMessage.value = response.message
    sentResetLink.value = true
  }
}
</script>

<template>
  <template v-if="sentResetLink">
    <div class="tw-flex tw-flex-col tw-gap-y-6">
      <div v-text="resetLinkConfirmationMessage" />

      <div class="tw-text-center">
        <LinkButton @click="authStore.setCurrentPage('login')">
          Go back
        </LinkButton>
      </div>
    </div>
  </template>

  <template v-else>
    <form class="tw-mt-6 tw-flex tw-flex-col tw-gap-y-6" @submit.prevent="handlePasswordReset">
      <FormInput v-model="form.email" field="email" label="Email address" :autofocus="true" />

      <PrimaryButton as="submit" class="tw-w-full">
        Send password reset link
      </PrimaryButton>
    </form>

    <div class="tw-text-center">
      <LinkButton @click="authStore.setCurrentPage('login')">
        Go back
      </LinkButton>
    </div>
  </template>
</template>
