export const withoutProtocol = (urlString) => {
  try {
    const url = new URL(urlString)
    return url.hostname
  }
  catch (error) {
    console.error('Invalid URL:', error)
    return ''
  }
}
