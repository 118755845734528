<script setup>
import { computed } from 'vue'
import { Switch } from '@headlessui/vue'

const props = defineProps({
  size: {
    type: String,
    default: 'md',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: false,
    default: 'Toggle',
  },
  disabledBorder: {
    type: String,
    required: false,
    default: 'tw-border-accent-100',
  },
  disabledBackground: {
    type: String,
    required: false,
    default: 'tw-bg-gray-300',
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const sizeClasses = {
  sm: {
    switch: 'tw-h-5 tw-w-8',
    track: 'tw-h-3.5 tw-w-7',
    thumb: 'tw-h-[16px] tw-w-[16px]',
  },
  md: {
    switch: 'tw-h-5 tw-w-10',
    track: 'tw-h-4 tw-w-9',
    thumb: 'tw-h-5 tw-w-5',
  },
  lg: {
    switch: '',
    track: '',
    thumb: '',
  },
}
const sizeClass = computed(() => sizeClasses[props.size])
</script>

<template>
  <div class="tw-flex tw-justify-center">
    <Switch v-model="model" :class="sizeClass.switch" class="tw-group tw-relative tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full focus:tw-bg-transparent">
      <span class="tw-sr-only" v-text="title" />

      <span aria-hidden="true" class="tw-pointer-events-none tw-absolute tw-mx-auto tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out" :class="[model ? 'tw-bg-accent-100' : disabledBackground, sizeClass.track]" />
      <span aria-hidden="true" class="tw-pointer-events-none tw-absolute tw-left-0 tw-inline-block tw-rounded-full tw-border tw-bg-white tw-shadow-sm tw-ring-0 tw-transition-transform tw-duration-200 tw-ease-in-out" :class="[model ? 'tw-translate-x-5' : 'tw-translate-x-0', model ? 'tw-border-accent-100' : disabledBorder, sizeClass.thumb]" />
    </Switch>
  </div>
</template>
