<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: 'md',
  },
  color: {
    type: String,
    default: 'tw-bg-gray-500',
  },
})

const sizes = ref({
  xs: 'tw-h-2 tw-w-2 tw-m-px',
  sm: 'tw-h-2.5 tw-w-2.5 tw-m-0.5',
  md: 'tw-h-3 tw-w-3 tw-m-0.5',
  lg: 'tw-h-5 tw-w-5 tw-m-1',
  xl: 'tw-h-7 tw-w-7 tw-m-1.5',
})

const size = computed(() => sizes.value[props.size])
</script>

<template>
  <div>
    <span :class="[color, size]" class="tw-inline-block tw-animate-[fade-in_0.6s_infinite_alternate] tw-rounded-full tw-align-middle" />
    <span :class="[color, size]" class="tw-inline-block tw-animate-[fade-in_0.6s_infinite_0.2s_alternate] tw-rounded-full tw-align-middle" />
    <span :class="[color, size]" class="tw-inline-block tw-animate-[fade-in_0.6s_infinite_0.4s_alternate] tw-rounded-full tw-align-middle" />
  </div>
</template>
