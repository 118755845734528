import { computed, reactive } from 'vue'
import axios from 'axios'

const state = reactive({
  errors: {
    validation: {},
  },
  downloading: false,
})

const validationErrors = computed(() => state.errors.validation)

const setValidationErrors = (error) => {
  switch (error.response.status) {
    case 419:
      window.location.reload()
      break
    case 422:
      state.errors.validation = error.response.data.errors
  }

  return Promise.reject(error)
}

const clearValidationError = field => delete state.errors.validation[field]

const clearValidationErrors = () => state.errors.validation = {}

const http = () => {
  clearValidationErrors()
  const http = axios.create({ baseURL: '/' })

  http.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content
  http.interceptors.response.use(response => response, error => setValidationErrors(error))

  return http
}

const downloadFile = async (url, name) => {
  try {
    state.downloading = true

    const response = await http().get(url, { responseType: 'blob' })
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = blobUrl
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  catch (error) {
    await setValidationErrors(error)
  }
  finally {
    state.downloading = false
  }
}

export { http, clearValidationError, clearValidationErrors, validationErrors, downloadFile, state }
