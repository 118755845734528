<script setup>
import { computed, ref, watch } from 'vue'
import Toggle from '@forms/Toggle.vue'
import { http } from '@modules/useHttp'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  status: {
    type: Number,
    required: true,
  },
})

const isPublished = ref(props.status === 1)
const title = computed(() => isPublished.value ? 'Marketing is live' : 'Marketing is in draft mode')

const handleIsPublished = active => isPublished.value = active

watch(() => isPublished.value, (active) => {
  http().patch(`/company-marketing/package/${props.id}/publish`, { active })
})
</script>

<template>
  <div :class="[isPublished ? 'tw-border-accent-100' : 'tw-border-red-400']" class="tw-flex tw-flex-col tw-items-center tw-gap-y-3 tw-rounded-md tw-border-4 tw-p-4">
    <div :class="[isPublished ? 'tw-text-accent-100' : 'tw-text-red-400']" class="tw-text-2xl tw-font-bold" v-text="title" />

    <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xl">
      <div class="tw-text-right">
        <button @click="handleIsPublished(false)">
          Draft
        </button>
      </div>

      <Toggle v-model="isPublished" disabled-background="tw-bg-red-400" disabled-border="tw-border-red-500" />

      <div class="tw-text-left">
        <button @click="handleIsPublished(true)">
          Published
        </button>
      </div>
    </div>

    <p>
      Must set to publish to allow agent access
    </p>
  </div>
</template>

<style scoped>
button:focus {
  background-color: transparent;
}
</style>
