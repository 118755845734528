<script setup>
import { Dialog, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})
</script>

<template>
  <TransitionRoot as="template" :show="model">
    <Dialog as="div" class="tw-relative tw-z-[998]" @close="model = false">
      <TransitionChild as="template" enter="tw-ease-out tw-duration-300" enter-from="tw-opacity-0" enter-to="tw-opacity-100" leave="tw-ease-in tw-duration-200" leave-from="tw-opacity-100" leave-to="tw-opacity-0">
        <div class="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-transition-opacity" />
      </TransitionChild>

      <div class="tw-fixed tw-inset-0 tw-z-10">
        <slot />
      </div>
    </Dialog>
  </TransitionRoot>
</template>
