<script setup>
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@stores/auth'
import Auth from '@components/auth/Auth.vue'
import SlideUpModal from '@shared/modals/SlideUpModal.vue'

const props = defineProps({
  primary: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  redirect: {
    type: String,
    default: '',
  },
  socialError: {
    type: Object,
    default: () => ({}),
  },
})

const authStore = useAuthStore()
const isOpen = ref(props.open)

onMounted(() => {
  if (props.primary && Object.prototype.hasOwnProperty.call(props.socialError, 'social')) {
    isOpen.value = true
  }
})
</script>

<template>
  <span @click="isOpen = true">
    <slot />
  </span>

  <SlideUpModal v-model="isOpen" width="lg" height="xl" bg="tw-bg-white">
    <div class="tw--mt-10 tw-flex tw-h-full tw-flex-col tw-justify-center tw-overflow-y-auto">
      <div class="tw-flex tw-max-h-full tw-flex-col tw-gap-y-6">
        <div>
          <div class="tw-flex tw-justify-center">
            <img src="@icons/logo.svg" class="tw-h-16 tw-font-medium" alt="amp logo">
          </div>

          <h3 class="tw-mt-4 tw-text-center tw-font-serif tw-text-2xl/8 tw-font-medium tw-text-primary-100" v-text="authStore.currentPageTitle" />
        </div>

        <Auth :open="isOpen" :redirect="redirect" :social-error="socialError" />
      </div>
    </div>
  </SlideUpModal>
</template>
