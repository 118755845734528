import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { clearValidationErrors } from '@modules/useHttp'

const pageTitles = {
  'login': 'Agent Sign-in',
  'forgot-password': 'Forgot Password',
  'reset-password': 'Reset Password',
}

export const useAuthStore = defineStore('auth', () => {
  const state = ref({
    currentPage: 'login',
    currentPageTitle: 'Agent Sign-in',
  })

  const currentPage = computed(() => state.value.currentPage)

  const currentPageTitle = computed(() => state.value.currentPageTitle)

  const isForgotPasswordPage = computed(() => currentPage.value === 'forgot-password')

  const setCurrentPage = (page) => {
    clearValidationErrors()
    state.value.currentPage = page
    state.value.currentPageTitle = pageTitles[page]
  }

  const setCurrentPageTitle = title => state.value.currentPageTitle = title

  return { isResetPasswordRequestPage: isForgotPasswordPage, currentPageTitle, setCurrentPage, setCurrentPageTitle }
})
