<script setup>
import { useAuthStore } from '@stores/auth'
import Login from '@components/auth/Login.vue'
import ForgotPassword from '@components/auth/ForgotPassword.vue'

defineProps({
  open: {
    type: Boolean,
    default: true,
  },
  redirect: {
    type: String,
    default: '',
  },
  socialError: {
    type: Array,
    default: () => ([]),
  },
})

const authStore = useAuthStore()
</script>

<template>
  <ForgotPassword v-if="authStore.isResetPasswordRequestPage" />
  <Login v-else :open="open" :redirect="redirect" :social-error="socialError" />
</template>
