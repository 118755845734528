<script setup lang="ts">
import { computed } from 'vue'
import { useDomainStore } from '@stores/domain'
import { PrimaryButton } from '@forms/index'
import SuccessAlert from '@shared/alerts/SuccessAlert.vue'

const domainStore = useDomainStore()
const reserveDomainTitle = computed(() => `${domainStore.availableDomain.domain} is available.`)
const reserveDomainHandler = () => {
  domainStore.setActiveStep('Verify')
  domainStore.prepareDomain()
}

const contactUsHandler = () => {
  window.location.href = `mailto:amp@mrtsystems.com?subject=Would like to purchase ${domainStore.availableDomain.domain}&body=Please contact me regarding the purchase of ${domainStore.availableDomain.domain}, for the marketing package found at ${window.location.href}`
}
</script>

<template>
  <SuccessAlert class="tw-mt-8">
    <div v-text="reserveDomainTitle" />
  </SuccessAlert>

  <div v-if="domainStore.availableDomain.price > 0" class="tw-flex tw-items-center tw-gap-x-6 tw-py-5">
    <div class="tw-hidden tw-flex-1 tw-leading-6 tw-text-gray-900 sm:tw-block" v-text="domainStore.availableDomain.domain" />

    <div class="tw-flex-1 tw-text-sm tw-leading-6 tw-text-gray-900 sm:tw-text-right">
      <span class="tw-text-xl tw-font-medium" v-text="`$${domainStore.availableDomain.price}`" />
      USD/yr
    </div>

    <div class="tw-justify-end">
      <PrimaryButton size="lg" @click="reserveDomainHandler">
        Reserve Now
      </PrimaryButton>
    </div>
  </div>

  <div v-else class="tw-mt-4 tw-flex tw-justify-end">
    <PrimaryButton size="lg" class="tw-justify-end" @click="contactUsHandler">
      Contact Us to Purchase
    </PrimaryButton>
  </div>
</template>
