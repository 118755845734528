const widthStyles = {
  'xs': 'sm:tw-max-w-xs',
  'sm': 'sm:tw-max-w-sm',
  'md': 'sm:tw-max-w-md',
  'lg': 'sm:tw-max-w-lg',
  'xl': 'sm:tw-max-w-xl',
  '2xl': 'sm:tw-max-w-2xl',
  '3xl': 'sm:tw-max-w-3xl',
  '4xl': 'sm:tw-max-w-4xl',
  '5xl': 'sm:tw-max-w-5xl',
  '6xl': 'sm:tw-max-w-6xl',
  '7xl': 'sm:tw-max-w-7xl',
  'full': '',
}

export const widthStyle = width => widthStyles[width]
