<script setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'

const props = defineProps({
  menuPosition: {
    type: String,
    default: 'left',
  },
})

const menuClasses = {
  left: 'tw-left-0',
  right: 'tw-right-0 sm:tw-left-0',
}
const menuClass = menuClasses[props.menuPosition]
</script>

<template>
  <Menu v-slot="{ open }" as="div" class="tw-relative tw-inline-block tw-text-left">
    <div>
      <MenuButton class="focus:tw-bg-transparent">
        <slot />
      </MenuButton>
    </div>

    <transition enter-active-class="tw-transition tw-ease-out tw-duration-100" enter-from-class="tw-transform tw-opacity-0 tw-scale-95" enter-to-class="tw-transform tw-opacity-100 tw-scale-100" leave-active-class="tw-transition tw-ease-in tw-duration-75" leave-from-class="tw-transform tw-opacity-100 tw-scale-100" leave-to-class="tw-transform tw-opacity-0 tw-scale-95">
      <div v-show="open">
        <MenuItems static :class="menuClass" class="tw-absolute tw-z-10 tw-mt-2 tw-w-56 tw-origin-top-right tw-divide-y tw-divide-gray-300 tw-rounded-md tw-bg-white tw-shadow-md tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
          <slot name="items" />
        </MenuItems>
      </div>
    </transition>
  </Menu>
</template>
