<script setup>
import { computed, reactive } from 'vue'
import { useDomainStore } from '@stores/domain'
import { http } from '@modules/useHttp'
import { FormInput } from '@forms/index'
import ReserveDomain from '@components/domains/reserve/ReserveDomain.vue'
import AlternativeDomainsList from '@components/domains/reserve/AlternativeDomainsList.vue'
import SecondaryButton from '@forms/SecondaryButton.vue'
import { route } from '@/config'

const domainStore = useDomainStore()

const state = reactive({
  searchedDomain: '',
  alternativeDomains: [],
  isCheckingDomain: false,
  showReserveDomain: false,
  showAlternativeDomains: false,
})

const domain = computed({
  get: () => domainStore.search,
  set: value => domainStore.setDomain(value),
})

const suggestedDomains = async (domain) => {
  const { data } = await http().post(route.domain.suggest, { domain })
  return data.filter(({ available, price }) => available && price > 0)
}

const checkDomainHandler = async () => {
  state.showAlternativeDomains = false
  state.showReserveDomain = false
  state.isCheckingDomain = true
  state.searchedDomain = domain.value

  try {
    const { data } = await http().post(route.domain.search, { domain: domain.value })

    if (!data.available) {
      state.alternativeDomains = await suggestedDomains(data.domain)
      state.showAlternativeDomains = true
      state.isCheckingDomain = false

      return
    }

    domainStore.setAvailableDomain(data)
    state.showReserveDomain = true
    state.isCheckingDomain = false
  }
  catch (e) {
    throw new Error(e)
  }
}
</script>

<template>
  <div class="tw-mt-16 tw-rounded-lg tw-bg-white tw-px-4 tw-py-8 sm:tw-mt-8 sm:tw-px-16">
    <FormInput
      v-model="domain"
      label="Find your web domain name"
      placeholder="Search domains"
      class="tw-mt-4"
      label-class="tw-text-primary-200 tw-text-xl"
      @keyup.enter="checkDomainHandler"
    />

    <div class="tw-mt-3 tw-flex tw-items-center tw-gap-x-4">
      <SecondaryButton class="tw-w-full sm:tw-w-56" :is-submitting="state.isCheckingDomain" submitting-text="Checking" icon="MagnifyingGlassIcon" @click="checkDomainHandler">
        Check
      </SecondaryButton>
    </div>

    <ReserveDomain v-if="state.showReserveDomain" />
    <AlternativeDomainsList v-if="state.showAlternativeDomains" :searched-domain="state.searchedDomain" :domains="state.alternativeDomains" />
  </div>
</template>
