<script setup>
import { onMounted } from 'vue'
import {
  cardCvcRef, cardExpiryRef, cardNumberRef, getCardPostalCode, getStripeErrors, initStripe,
} from '@modules/useStripe'
import PrimaryButton from '@forms/PrimaryButton.vue'

defineProps({
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  submittingText: {
    type: String,
    default: 'Processing',
  },
  handleSubmit: {
    type: Function,
    required: true,
  },
})

onMounted(async () => await initStripe())
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div>
      <label for="card_number" class="tw-relative tw-left-0 tw-text-base">Credit Card Number</label>
      <div id="card_number" ref="cardNumberRef" class="tw-relative tw-mt-2 tw-h-10 tw-rounded tw-border tw-border-temp-zinc-400 tw-bg-transparent tw-bg-[length:100px_17px] tw-bg-no-repeat tw-p-2 tw-leading-5 tw-text-temp-zinc-800" style="background: url(../../../images/credit-cards.png)no-repeat 98% 50% transparent;" />
    </div>

    <div class="tw-mt-4 tw-flex tw-flex-col tw-gap-6 sm:tw-flex-row">
      <div class="tw-w-full sm:tw-w-1/2">
        <label for="card_expiry" class="tw-relative tw-left-0 tw-text-base">Expiration</label>
        <div id="card_expiry" ref="cardExpiryRef" class="tw-relative tw-mt-2 tw-h-10 tw-rounded tw-border tw-border-temp-zinc-400 tw-p-2 tw-leading-5 tw-text-temp-zinc-800" />
      </div>

      <div class="tw-w-full sm:tw-w-1/4">
        <label for="card_cvc" class="tw-relative tw-left-0 tw-text-base">CCV</label>
        <div id="card_cvc" ref="cardCvcRef" class="tw-relative tw-mt-2 tw-h-10 tw-rounded tw-border tw-border-temp-zinc-400 tw-p-2 tw-leading-5 tw-text-temp-zinc-800" />
      </div>

      <div class="tw-w-full sm:tw-w-1/4">
        <label for="card_zip" class="tw-relative tw-left-0 tw-text-base">Zip Code</label>
        <input id="card_zip" v-model="getCardPostalCode" type="text" placeholder="zip" required maxlength="10" class="browser-default tw-relative tw-mt-2 tw-h-10 tw-rounded tw-border tw-border-temp-zinc-400 tw-bg-transparent tw-p-2 tw-leading-5 tw-text-primary-200 focus:tw-shadow-none focus:tw-outline-none focus:tw-ring-0">
      </div>
    </div>

    <div>
      <div role="alert" class="tw-ml-4 tw-mt-2 tw-text-left tw-text-red-700" v-text="getStripeErrors.card.response" />
      <div role="alert" class="tw-ml-4 tw-mt-2 tw-text-left tw-text-red-700" v-text="getStripeErrors.card.number" />
      <div role="alert" class="tw-ml-4 tw-mt-2 tw-text-left tw-text-red-700" v-text="getStripeErrors.card.expiry" />
      <div role="alert" class="tw-ml-4 tw-mt-2 tw-text-left tw-text-red-700" v-text="getStripeErrors.card.cvc" />
    </div>

    <div class="tw-mt-4">
      <PrimaryButton
        class="tw-w-full"
        as="submit"
        size="lg"
        :is-disabled="isDisabled"
        :is-submitting="isSubmitting"
        :submitting-text="submittingText"
      >
        Confirm Order
      </PrimaryButton>
    </div>
  </form>
</template>
