<script setup>
import BaseModal from '@shared/modals/BaseModal.vue'
import { DialogPanel, TransitionChild } from '@headlessui/vue'
import Icon from '@shared/icons/Icon.vue'
import { widthStyle } from '@modules/useModal'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
  height: {
    type: String,
    default: 'auto',
  },
  width: {
    type: String,
    default: '2xl',
  },
  bg: {
    type: String,
    default: 'tw-bg-temp-gray-100',
  },
})

const emit = defineEmits({ 'update:modelValue': null })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const heightStyles = {
  auto: 'tw-h-mobile-screen-my-2 md-tall:tw-h-auto',
  xl: 'tw-h-mobile-screen-my-2 md-tall:tw-h-[740px]',
}

const heightStyle = height => heightStyles[height]
</script>

<template>
  <BaseModal v-model="model">
    <div class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-px-4 tw-text-center sm:tw-items-center">
      <TransitionChild :class="heightStyle(height)" class="tw-flex-1 tw-overflow-y-auto sm:tw-px-12 sm:tw-pb-8" as="template" enter="tw-transform tw-transition tw-ease-out-in tw-duration-1000" enter-from="tw-translate-y-full" enter-to="tw-translate-y-0" leave="tw-transform tw-transition tw-ease-out-in tw-duration-1000" leave-from="tw-translate-y-0" leave-to="tw-translate-y-full">
        <DialogPanel :class="[widthStyle(width), bg]" class="tw-shadow tw-relative tw-overflow-hidden tw-rounded-t-lg tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-rounded-lg sm:tw-p-6">
          <div class="tw--mr-2 tw--mt-2 tw-text-right sm:tw--mr-8 sm:tw--mt-2">
            <button class="tw-text-gray-700 hover:tw-text-gray-800 focus:tw-bg-transparent" @click="model = false">
              <Icon icon="XMarkIcon" class="tw-h-10 tw-w-10" />
            </button>
          </div>

          <slot />
        </DialogPanel>
      </TransitionChild>
    </div>
  </BaseModal>
</template>
