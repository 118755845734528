<script setup>
// Find more icons at https://heroicons.com
import {
  BookmarkIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  CursorArrowRippleIcon,
  DocumentDuplicateIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid'

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/24/outline'

// Custom icons
import { DesktopIcon, DownloadIcon, DownloadLegacyIcon, EditButtonIcon, NewBadgeIcon, ShareIcon } from '@shared/icons/index'

defineProps({
  icon: {
    type: String,
    required: true,
  },
})

const iconMap = {
  BookmarkIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  CursorArrowRippleIcon,
  DesktopIcon,
  DocumentDuplicateIcon,
  DownloadIcon,
  DownloadLegacyIcon,
  EditButtonIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  NewBadgeIcon,
  ShareIcon,
  ShieldCheckIcon,
  XCircleIcon,
  XMarkIcon,
}
</script>

<template>
  <component :is="iconMap[icon]" aria-hidden="true" />
</template>
