import { computed, reactive, ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import * as Sentry from '@sentry/vue'
import { http } from '@modules/useHttp'
import { env, stripeStyles as style } from '@/config'

let stripe, cardNumber, cardExpiry, cardCvc

const state = reactive({
  clientSecret: '',
  confirmation: {
    id: '',
    amount: 0,
  },
  paymentAmount: 0,
  card: {
    postalCode: '',
  },
  errors: {
    card: {
      response: '',
      number: '',
      expiry: '',
      cvc: '',
    },
  },
})

export const cardNumberRef = ref(null)
export const cardExpiryRef = ref(null)
export const cardCvcRef = ref(null)

const getStripePromise = loadStripe(env.stripePublicKey)

export const getCardPostalCode = ref(state.card.postalCode)

const getClientSecret = () => state.clientSecret

export const getConfirmation = computed(() => state.confirmation)

export const getPaymentAmount = computed(() => state.paymentAmount)

export const getStripeErrors = computed(() => state.errors)

const setClientSecret = clientSecret => state.clientSecret = clientSecret

const setConfirmation = confirmation => state.confirmation = confirmation

const setPaymentAmount = paymentAmount => state.paymentAmount = paymentAmount

const setStripeCardError = (field, error) => state.errors.card[field] = (error) ? error.message : ''

export const initStripe = async () => {
  stripe = await getStripePromise
  const elements = stripe.elements()

  cardNumber = elements.create('cardNumber', { style })
  cardExpiry = elements.create('cardExpiry', { style })
  cardCvc = elements.create('cardCvc', { style })

  cardNumber.mount(cardNumberRef.value)
  cardExpiry.mount(cardExpiryRef.value)
  cardCvc.mount(cardCvcRef.value)

  cardNumber.on('change', ({ error }) => setStripeCardError('number', error))
  cardExpiry.on('change', ({ error }) => setStripeCardError('expiry', error))
  cardCvc.on('change', ({ error }) => setStripeCardError('cvc', error))
}

export const updatePaymentIntent = async (intentRoute, payload) => {
  try {
    const { data } = await http().post(intentRoute, payload)
    setClientSecret(data.client_secret)
    setPaymentAmount(data.payment_amount)
  }
  catch (e) {
    throw new Error(e)
  }
}

export const submitPaymentIntent = async ({ name, email, phone }) => {
  try {
    const { error, paymentIntent } = await stripe.confirmCardPayment(getClientSecret(), {
      payment_method: {
        card: cardNumber,
        billing_details: {
          address: {
            postal_code: getCardPostalCode.value,
          },
          email,
          name,
          phone,
        },
      },
      receipt_email: email,
    })

    if (error) {
      return { code: 422 }
    }

    if (paymentIntent.status === 'succeeded') {
      setConfirmation({
        id: paymentIntent.id,
        amount: paymentIntent.amount,
      })

      return { code: 201 }
    }
  }
  catch (error) {
    Sentry.captureException(error)
    setStripeCardError('response', { message: 'There was an issue with payment. The domain was not registered. Please contact us to sort this out!' })

    return { code: 400 }
  }
}
