<script setup>
import { ref } from 'vue'
import { useDomainStore } from '@stores/domain'
import { formatUsd } from '@modules/useCurrency'
import PrimaryButton from '@forms/PrimaryButton.vue'
import Icon from '@shared/icons/Icon.vue'
import InfoAlert from '@shared/alerts/InfoAlert.vue'

const domainStore = useDomainStore()

const isSubmitting = ref(false)
const submittingText = ref('Processing')

const handleSubmit = async () => {
  isSubmitting.value = true
  submittingText.value = 'Registering Domain'
  await domainStore.registerDomain()
  domainStore.setActiveStep('Reserved')
}
</script>

<template>
  <div class="tw-mx-auto tw-mt-16 tw-rounded-lg tw-bg-white tw-px-4 tw-py-8 sm:tw-mt-8 sm:tw-max-w-lg sm:tw-px-16">
    <div class="tw-mx-auto tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-temp-blue-50">
      <Icon icon="GlobeAltIcon" class="tw-h-16 tw-w-16 tw-text-temp-blue-500" aria-hidden="true" />
    </div>

    <div class="tw-text-center">
      <div class="tw-mt-6 tw-text-xl tw-font-medium">
        You are about to reserve:
      </div>

      <div class="tw-flex-1 tw-text-2xl tw-text-accent-100" v-text="domainStore.availableDomain.domain" />
    </div>

    <p class="tw-mt-4">
      This domain has an annual cost of <span class="tw-font-medium" v-text="formatUsd(domainStore.availableDomain.price)" />, but the company will cover the cost of this domain name for 1 year.
    </p>

    <InfoAlert class="tw-mt-8">
      Please check the spelling - once a domain is purchased, it cannot be reversed!
    </InfoAlert>

    <div class="tw-mt-8">
      <PrimaryButton
        class="tw-w-full"
        as="submit"
        size="lg"
        :is-submitting="isSubmitting"
        :submitting-text="submittingText"
        @click="handleSubmit"
      >
        Confirm Order
      </PrimaryButton>
    </div>
  </div>
</template>
