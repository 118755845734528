<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import { useDomainStore } from '@stores/domain'
import { http } from '@modules/useHttp'
import { MediaItem } from '@components/marketing/media'

const props = defineProps({
  listingId: {
    type: Number,
    required: true,
  },
  propMlsId: {
    type: Number,
    required: true,
  },
  address: {
    type: String,
    required: true,
  },
})

const domainStore = useDomainStore()

const thumbnail = ref('')
const downloadName = ref('')
const downloadUrl = ref('')

const fetchBrochure = async () => {
  thumbnail.value = ''
  downloadName.value = ''
  downloadUrl.value = ''

  const response = await http().get(`/ui/marketing/prop-mls/${props.propMlsId}/media/BROCHURE`, {
    params: {
      address: props.address,
      width: 96,
      height: 120,
    },
  })

  thumbnail.value = response.data.thumbnail
  downloadName.value = response.data.downloadName
  downloadUrl.value = response.data.downloadUrl
}

onMounted(() => fetchBrochure())
watchEffect(() => domainStore.isReserved && fetchBrochure())

const items = [
  { name: 'Single Page', value: 1 },
]
</script>

<template>
  <MediaItem
    title="Brochure"
    :item="1"
    :items="items"
    :thumbnail="thumbnail"
    :thumbnail-mobile="{ width: '90px', height: '112px' }"
    :thumbnail-desktop="{ width: '94px', height: '120px' }"
    impression-category="marketing"
    impression-type="brochure"
    :impression-id="listingId"
    :download-file-name="downloadName"
    :download-file-url="downloadUrl"
    :is-new="true"
  />
</template>
