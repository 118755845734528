import { http } from '@modules/useHttp'
import { route } from '@/config'

export const login = async (form) => {
  try {
    const { data } = await http().post(route.auth.login, form)

    if (!form.redirect) {
      window.location.reload()
      return
    }

    window.location.href = data.redirect
  }
  catch (error) {
    console.error(error.message)
  }
}

export const logout = async (redirect) => {
  try {
    await http().post(route.auth.logout)

    if (redirect) {
      window.location.href = redirect
      return
    }

    window.location.reload()
  }
  catch (error) {
    console.error(error.message)
  }
}

export const forgotPassword = async (form) => {
  try {
    const { data } = await http().post(route.auth.forgotPassword, form)

    return data
  }
  catch (error) {
    console.error(error.message)
  }
}

export const resetPassword = async (form) => {
  try {
    const { data } = await http().post(route.auth.resetPassword, form)

    return data
  }
  catch (error) {
    console.error(error.message)
  }
}
