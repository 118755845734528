<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@stores/auth'
import { resetPassword } from '@modules/useAuth'
import { FormInput, PrimaryButton } from '@forms/index'
import AuthPage from '@pages/auth/AuthPage.vue'
import { route } from '@/config'

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  token: {
    type: String,
    required: true,
  },
})

const authStore = useAuthStore()
authStore.setCurrentPage('reset-password')

const form = ref({
  token: props.token,
  email: props.email,
  password: '',
  password_confirmation: '',
})
const passwordIsReset = ref(false)
const passwordResetMessage = ref('')

const handleResetPassword = async () => {
  const response = await resetPassword(form.value)
  passwordResetMessage.value = response.message
  passwordIsReset.value = true
}
</script>

<template>
  <AuthPage>
    <template v-if="passwordIsReset">
      <div class="tw-flex tw-flex-col tw-gap-y-6">
        <div v-text="passwordResetMessage" />

        <div class="tw-text-center">
          <a :href="route.auth.login" class="tw-text-center">
            Login with your new password
          </a>
        </div>
      </div>
    </template>

    <template v-else>
      <form class="tw-flex tw-flex-col tw-gap-y-6" @submit.prevent="handleResetPassword">
        <FormInput v-model="form.email" field="email" label="Email address" :disabled="true" />
        <FormInput v-model="form.password" type="password" label="Password" :autofocus="true" />
        <FormInput v-model="form.password_confirmation" type="password" label="Password Confirmation" />

        <PrimaryButton as="submit" class="tw-w-full">
          Reset Password
        </PrimaryButton>
      </form>
    </template>
  </AuthPage>
</template>
