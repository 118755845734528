<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useClipboard } from '@vueuse/core'
import { MenuItem } from '@headlessui/vue'
import { notify } from 'notiwind'
import { http } from '@modules/useHttp'
import { useDomainStore } from '@stores/domain'
import { withoutProtocol } from '@modules/useUrl'
import SecondaryButton from '@forms/SecondaryButton.vue'
import LinkButton from '@forms/LinkButton.vue'
import Dropdown from '@shared/Dropdown.vue'
import Icon from '@shared/icons/Icon.vue'
import SlwOnlineToggle from '@components/domains/SlwOnlineToggle.vue'
import PurchaseDomainModal from '@components/modals/PurchaseDomainModal.vue'
import LoginModal from '@components/modals/LoginModal.vue'

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  user: {
    type: [Object, null],
    required: true,
  },
  canEdit: {
    type: Boolean,
    required: true,
  },
  company: {
    type: Object,
    required: true,
  },
  listingId: {
    type: Number,
    required: true,
  },
  address: {
    type: String,
    required: true,
  },
  city: {
    type: String,
    required: true,
  },
  state: {
    type: String,
    required: true,
  },
  zip: {
    type: String,
    required: true,
  },
  listingMeta: {
    type: Object,
    required: true,
  },
  status: {
    type: Number,
    required: true,
  },
  canEditSlw: {
    type: Boolean,
    required: true,
  },
  isAuthenticated: {
    type: Boolean,
    required: true,
  },
  isAdminUser: {
    type: Boolean,
    required: true,
  },
  domain: {
    type: Object,
    required: true,
  },
  openDomainModal: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: String,
    required: true,
  },
  socialLoginError: {
    type: Object,
    default: () => ({}),
  },
})

const domainStore = useDomainStore()

const resolvedDomain = ref(props.domain)

watchEffect(async () => {
  if (domainStore.isReserved) {
    try {
      const response = await http().get(`ui/slws/${props.id}/domain`)
      resolvedDomain.value = response.data.domain
    }
    catch (error) {
      throw new Error(error)
    }
  }
})

const canBuyDomain = computed(() => {
  if (props.company.id !== props.user?.company_id) {
    return false
  }

  if (props.company.domains_admin_only === 1 && !props.isAdminUser) {
    return false
  }

  if (props.company.domains_admin_only === 1 && props.isAdminUser) {
    return true
  }

  return props.canEdit
})

const { copy } = useClipboard({ legacy: true })

const copyIt = () => {
  copy(resolvedDomain.value.url)
  notify({ group: 'main', title: 'Copied!', text: `${withoutProtocol(resolvedDomain.value.url)} was copied to the clipboard` }, 4000)
}

const logImpression = (type) => {
  window.ampLogImpression(`slw/${props.id}/${type}`, props.id)
}

const urlText = computed(() =>
  resolvedDomain.value.active ? withoutProtocol(resolvedDomain.value.url) : `${withoutProtocol(resolvedDomain.value.url)} pending`)
const openDomain = () => window.open(resolvedDomain.value.url, '_blank')

const rsirLoginUrl = () => {
  const rfgId = Object.values(props.listingMeta).find(meta => meta.key === 'rfg_id')?.value

  if (rfgId) {
    return `https://rsirconnect.com/sso/neutrino/aml-${rfgId}`
  }

  return 'https://rsirconnect.com'
}
</script>

<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-y-4">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-y-10 md:tw-gap-y-2">
      <h4 class="tw-mb-0 tw-flex tw-items-center tw-gap-x-2 tw-font-sans tw-text-sm tw-text-primary-300 md:tw-py-0 md:tw-text-xs md:tw-text-black">
        <Icon icon="DesktopIcon" class="tw-h-4" />
        <a class="tw-text-primary-300 md:tw-text-black" :href="resolvedDomain.url" target="_blank" @click="logImpression('view')">
          <span class="tw-hidden tw-font-bold tw-uppercase md:tw-inline">Property Website</span>
          <span class="tw-font-normal md:tw-hidden" v-text="urlText" />
        </a>
      </h4>

      <div>
        <div class="tw-relative tw-h-[164px] tw-w-[228px] tw-bg-[url(@images/computer-graphic.png)] tw-bg-cover">
          <a :href="resolvedDomain.url" target="_blank" @click="logImpression('view')">
            <img :src="thumbnail" alt="Website Preview" class="tw-absolute tw-left-1 tw-top-1 tw-h-[127px] tw-w-[220px] tw-rounded-sm">
          </a>

          <template v-if="isAuthenticated">
            <a v-if="canEditSlw" class="tw-absolute tw-bottom-11 tw-right-2 tw-text-primary-200" :href="`/slw/${listingId}/edit`" title="Edit Website">
              <Icon icon="EditButtonIcon" class="tw-h-10" />
            </a>
            <a v-else-if="company.slug === 'rsir'" class="tw-absolute tw-bottom-11 tw-right-2 tw-text-primary-200" href="https://rsirconnect.com/marketing/5ee66248d7624d22c1f479de/order" title="Request Website Changes" target="_blank">
              <Icon icon="EditButtonIcon" class="tw-h-10" />
            </a>
          </template>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-w-3/4 tw-flex-wrap tw-items-center tw-justify-center tw-gap-2">
      <div class="tw-hidden md:tw-block">
        <a class="tw-text-base tw-text-primary-300" target="_blank" :href="resolvedDomain.url" @click="logImpression('view')" v-text="urlText" />
      </div>

      <div v-if="isAuthenticated && canEdit">
        <SlwOnlineToggle :id="id" :status="status" />
      </div>

      <div v-if="!domain.custom && canBuyDomain">
        <PurchaseDomainModal
          :open="openDomainModal"
          :ala-carte="company.domains_ala_carte === 1"
          :listing-id="listingId"
          :name="`${user.first_name} ${user.last_name}}`"
          :email="user.email"
          :phone="user.phone"
          :address="address"
          :city="city"
          :state="state"
          :zip="zip"
        >
          <LinkButton
            v-if="!resolvedDomain.custom"
            class-list="tw-text-accent-100"
            class="tw-inline tw-font-black tw-uppercase tw-underline"
          >
            Get Domain
          </LinkButton>
        </PurchaseDomainModal>
      </div>

      <div v-else-if="!isAuthenticated && company.slug === 'rsir'">
        <a class="tw-inline tw-font-black tw-uppercase tw-text-accent-100 tw-underline" :href="rsirLoginUrl()">Login to Add Domain</a>
      </div>

      <div v-else-if="!isAuthenticated">
        <LoginModal :social-error="socialLoginError">
          <LinkButton
            class-list="tw-text-accent-100"
            class="tw-inline tw-font-black tw-uppercase tw-underline"
          >
            Login to Add Domain
          </LinkButton>
        </LoginModal>
      </div>
    </div>

    <div class="tw-flex tw-gap-x-2">
      <SecondaryButton size="sm" @click="openDomain(); logImpression('view')">
        View website
      </SecondaryButton>

      <Dropdown menu-position="right">
        <SecondaryButton size="sm" icon="ShareIcon">
          Share
        </SecondaryButton>
        <template #items>
          <MenuItem v-slot="{ active }" class="tw-py-1" as="div">
            <button class="tw-flex tw-w-full tw-items-center tw-gap-x-3 tw-px-3 tw-py-2 tw-text-sm tw-font-medium" :class="[active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700']" @click="copyIt(); logImpression('copy')">
              <Icon icon="DocumentDuplicateIcon" class="tw-h-4" />
              Copy Link
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }" class="tw-py-1" as="div">
            <a
              :href="`sms:?body=Check out ${address} in ${city} at ${resolvedDomain.url}`"
              class="tw-flex tw-items-center tw-gap-x-3 tw-px-3 tw-py-2 tw-text-sm tw-font-medium"
              :class="[active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700']"
              @click="logImpression('text')"
            >
              <Icon icon="ChatBubbleLeftEllipsisIcon" class="tw-h-4" />
              Send Txt
            </a>
          </MenuItem>

          <MenuItem v-slot="{ active }" class="tw-py-1" as="div">
            <a
              :href="`mailto:?subject=Check out this listing, ${address} in ${city}&body=I wanted to share this listing with you, ${address} in ${city}.%0D%0A%0D%0AYou can view all the details here: ${resolvedDomain.url}`"
              class="tw-flex tw-items-center tw-gap-x-3 tw-px-3 tw-py-2 tw-text-sm tw-font-medium"
              :class="[active ? 'tw-bg-gray-100 tw-text-gray-900' : 'tw-text-gray-700']"
              @click="logImpression('email')"
            >
              <Icon icon="EnvelopeOpenIcon" class="tw-h-4" />
              Send Email
            </a>
          </MenuItem>
        </template>
      </dropdown>
    </div>
  </div>
</template>
