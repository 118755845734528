<script setup>
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useBreakpointsStore } from '@stores/breakpoints'
import { clearValidationError, validationErrors } from '@modules/useHttp'
import Icon from '@shared/icons/Icon.vue'
import ValidationTooltip from '@shared/alerts/ValidationTooltip.vue'
import { formatField } from '@modules/useForm'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  field: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    required: true,
  },
  labelClass: {
    type: String,
    default: 'tw-text-gray-2-300',
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 255,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits({ 'update:modelValue': null })
const breakpointsStore = useBreakpointsStore()

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
})

const inputRef = ref(null)
const placeholder = computed(() => {
  if (props.placeholder) {
    return props.placeholder
  }
  if (breakpointsStore.isSm) {
    return props.label
  }

  return ''
})

watchEffect(() => {
  if (props.autofocus && inputRef.value) {
    inputRef.value?.focus()
  }
})

onMounted(() => {
  let viewportMeta = document.querySelector('meta[name="viewport"]')
  if (!viewportMeta) {
    viewportMeta = document.createElement('meta')
    viewportMeta.name = 'viewport'
    document.head.appendChild(viewportMeta)
  }
  viewportMeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
})

onUnmounted(() => {
  const viewportMeta = document.querySelector('meta[name="viewport"]')
  if (viewportMeta) {
    viewportMeta.content = 'width=device-width, initial-scale=1.0'
  }
})

const field = formatField(props.field, props.label)
</script>

<template>
  <div class="tw-relative tw-flex tw-flex-col tw-gap-y-1">
    <label v-if="hideLabel || breakpointsStore.isSm" :for="field" class="tw-sr-only" v-text="label" />
    <label v-else :for="field" class="tw-block tw-text-left tw-text-sm/5 tw-font-medium tw-text-gray-700" :class="labelClass" v-text="label" />

    <div class="tw-relative">
      <input
        :id="field"
        ref="inputRef"
        v-model="model"
        :type="type"
        :name="field"
        :placeholder="placeholder"
        :class="[hasError || validationErrors[field] ? 'tw-border-red-300 tw-text-red-900' : 'tw-border-gray-300 tw-text-black']"
        class="browser-default tw-block tw-w-full tw-rounded-md tw-border tw-py-1.5 tw-leading-6 tw-outline-none placeholder:tw-text-gray-400 focus:tw-border-accent-300 focus:tw-shadow-none focus:tw-ring-0 disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50 disabled:tw-text-gray-500 sm:tw-text-sm sm:tw-leading-6"
        :required="required"
        :disabled="disabled"
        :maxlength="maxlength"
        @keydown="clearValidationError(field)"
      >

      <div v-if="hasError || validationErrors[field]" class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
        <Icon icon="ExclamationCircleIcon" class="tw-h-5 tw-w-5 tw-text-red-500" aria-hidden="true" />
      </div>
    </div>

    <ValidationTooltip :message="validationErrors[field]" class="tw--bottom-[9px]" @click="clearValidationError(field)" />
  </div>
</template>
