export const env = {
  stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
}

export const route = {
  privacy: '/privacy',
  terms: '/terms',
  auth: {
    login: '/login',
    logout: '/logout',
    facebook: '/oauth/facebook',
    x: '/oauth/twitter',
    google: '/oauth/google',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
  },
  domain: {
    prepare: '/marketing/domain/prepare',
    search: '/marketing/domain/search',
    suggest: '/marketing/domain/suggest',
    register: '/marketing/domain/verify',
  },
  media: {
    download: '/media/download',
  },
}

export const colors = {
  'black': '#000000',
  'white': '#FFFFFF',
  'transparent': 'transparent',
  'primary-100': '#1B2E41',
  'primary-200': '#003364',
  'primary-300': '#285FBB',
  'secondary-100': '#007FFF',
  'secondary-200': '#FFF38B',
  'accent-100': '#32A69A',
  'accent-200': '#9DF0BE',
  'accent-300': '#99CCFF',
  'red-50': '#FEF2F2',
  'red-100': '#FEE2E2',
  'red-200': '#FECACA',
  'red-300': '#FCA5A5',
  'red-400': '#F87171',
  'red-500': '#EF4444',
  'red-600': '#DC2626',
  'red-700': '#B91C1C',
  'red-800': '#991B1B',
  'red-900': '#7F1D1D',
  'gray-50': '#F9FAFB',
  'gray-100': '#F3F4F6',
  'gray-200': '#E5E7EB',
  'gray-300': '#D1D5DB',
  'gray-400': '#9CA3AF',
  'gray-500': '#6B7280',
  'gray-600': '#4B5563',
  'gray-700': '#374151',
  'gray-800': '#1F2937',
  'gray-900': '#111827',
  'gray-2-100': '#E9E9E9',
  'gray-2-200': '#D9D9D9',
  'gray-2-300': '#9E9E9E',
  'temp-gray-100': '#F3F3F3',
  'temp-zinc-400': '#A1A1AA',
  'temp-zinc-800': '#27272A',
  'temp-yellow-50': '#FEFCE8',
  'temp-yellow-400': '#FACC15',
  'temp-yellow-700': '#A16207',
  'temp-green-50': '#F0FDF4',
  'temp-green-100': '#DCFCE7',
  'temp-green-400': '#4ADE80',
  'temp-green-600': '#16A34A',
  'temp-green-700': '#15803D',
  'temp-teal-700': '#0F766E',
  'temp-blue-50': '#EFF6FF',
  'temp-blue-400': '#60A5FA',
  'temp-blue-500': '#3B82F6',
  'temp-blue-700': '#1D4ED8',
}

export const buttonSizes = {
  xs: 'tw-text-2xs tw-h-6 tw-px-4',
  sm: 'tw-text-xs tw-h-8 tw-px-5',
  md: 'tw-text-sm tw-h-10 tw-px-8',
  lg: 'tw-text-base tw-h-12 tw-px-10',
}

export const circleButtonSizes = {
  md: 'tw-h-10 tw-w-10',
}

export const buttonIconSizes = {
  xs: 'tw-h-3',
  sm: 'tw-h-4',
  md: 'tw-h-5',
  lg: 'tw-h-6',
}

export const circleButtonIconSizes = {
  md: 'tw-h-6',
}

// see https://stripe.com/docs/js/appendix/style
export const stripeStyles = {
  base: {
    'color': colors['primary-200'],
    'fontWeight': '200',
    'fontFamily': '"BentonSans", "Arial", "Helvetica Neue", "Helvetica", sans-serif',
    'fontSize': '15px',
    'lineHeight': '1.2857',
    '::placeholder': {
    },
    ':-webkit-autofill': {
      color: '#E39f48',
    },
  },
  invalid: {
    'color': colors['red-900'],
    '::placeholder': {
      color: colors['red-900'],
    },
  },
}
