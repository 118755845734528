<script setup>
import { logout } from '@modules/useAuth'
import { route } from '@/config'

const props = defineProps({
  link: {
    type: String,
    default: '/logout',
  },
})

const isInternalLink = props.link === route.auth.logout

const handleLogout = () => {
  if (isInternalLink) {
    return logout('')
  }

  return logout(props.link)
}
</script>

<template>
  <form method="POST" @submit.prevent="handleLogout">
    <slot />
  </form>
</template>
